
/* html:not([dir="rtl"]) .sidebar {
    margin-left: -300px !important;
}

.sidebar {
    margin-left: 300px !important;
}

.sidebar-lg-show .app-body .main {
    margin-left: 0px !important;
} */

/* Desktop */
@media screen and (min-width: 992px){

    main.customMain {
        margin-left: 300px !important;
    }

    footer {
        margin-left: 300px !important;
    }

}

/* Tab */
@media screen and (max-width: 992px){

    main.customMain, footer.app-footer {
        margin-left: 0px !important;
    }

    html:not([dir="rtl"]) .sidebar {
        margin-left: -300px !important;
    }

    html:not([dir="rtl"]) .sidebar-show .sidebar, html:not([dir="rtl"]) .sidebar-show .sidebar {
        margin-left: 0 !important;
    }

    footer {
        margin-left: 0px !important;
    }
}

/* Mobile */
@media screen and (max-width: 560px){

    main.customMain, footer.app-footer {
        margin-left: 0px !important;
    }

    html:not([dir="rtl"]) .sidebar {
        margin-left: -300px !important;
    }

    html:not([dir="rtl"]) .sidebar-show .sidebar, html:not([dir="rtl"]) .sidebar-show .sidebar {
        margin-left: 0 !important;
    }

    .app-footer {
        margin-left: 0px !important;
    }

    footer {
        margin-left: 0px !important;
    }
}

#sticky-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  #sticky-table thead {
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  
  #sticky-table th {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  #sticky-table tbody {
    max-height: 300px; /* Set a fixed height for the tbody */
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .va-tc {
    width: 93% !important;
    border: 0.5px solid #c1c1c1 !important;
  }
  