// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

.editor {
  textarea#mentions {
    height: 350px;
  }

  div.card,
  .tox div.card {
    width: 240px;
    background: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(34, 47, 62, 0.1);
    padding: 8px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  }

  div.card::after,
  .tox div.card::after {
    content: '';
    clear: both;
    display: table;
  }

  div.card h1,
  .tox div.card h1 {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 8px;
    padding: 0;
    line-height: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  }

  div.card p,
  .tox div.card p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  }

  div.card img.avatar,
  .tox div.card img.avatar {
    width: 48px;
    height: 48px;
    margin-right: 8px;
    float: left;
  }
}

.imageFloatLeft {
  float: left;
  margin: 0 10px 0 10px;
}

.imageFloatRight {
  float: right;
  margin: 0 10px 0 10px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 10000;
}

.toast-container {
  background-color: rgba(0, 0, 0, 0.95);
  color: white;
  opacity: 0.95;
}
.Toastify__close-button--default {
  color: white;
  opacity: 0.8;
  font-size: 16px;
  &:hover {
    color: red;
  }
}
.toast-progress-style {
  height: 8px;
  // background: repeating-radial-gradient(circle at center, red 10px);
}


/* Mobile */
@media screen and (max-width: 560px){

  .app-footer {
      margin-left: 0px !important;
  }

  footer {
      margin-left: 0px !important;
  }

  .app footer {
      margin-left: 0px !important;
  }
}