// Here you can add other styles
.app-body .main {
  margin-left: 0px !important;
}
.sidebar-minimized.brand-minimized {
  .app-body .main {
    margin-left: 0px !important;
  }
  .app-footer {
    margin-left: 0px !important;
  }
}

.sidebar-lg-show {
  .app-body .main {
    margin-left: 300px !important;
  }
  .sidebar .sidebar-nav {
    width: 300px;
  }
  .sidebar {
    width: 300px;
  }
  .sidebar .nav {
    width: 300px;
  }

  .sidebar-fixed .sidebar {
    width: 300px;
  }
  .app-footer {
    margin-left: 300px !important;
  }
  &.sidebar-minimized.brand-minimized {
    .app-body .main {
      margin-left: 50px !important;
    }
    .app-footer {
      margin-left: 50px !important;
    }
    .sidebar .sidebar-nav {
      width: 50px;
    }
    .sidebar {
      width: 50px;
    }
    .sidebar .nav {
      width: 50px;
    }
  }
}

.sidebar-sm-show {
  .app-body .main {
    margin-left: 0px !important;
  }
  .sidebar .sidebar-nav {
    width: 0px;
  }
  .sidebar {
    width: 300px;
  }
  .sidebar .nav {
    width: 300px;
  }
}

.editor_body {
  border: 1px solid #f1f1f1;
  min-height: 150px;
  margin-bottom: 20px;
  padding: 0px 10px;
  &.error {
    border-color: red;
  }
}

.editor-wrapper {
  width: 90%;
}

.nav-link.active {
  background-color: rgba($color: #00805e, $alpha: 0.9) !important;
}

.nav-dropdown.open {
  background-color: rgba($color: #000000, $alpha: 0.5) !important;
}
.nav-dropdown.open {
  .nav-link.nav-dropdown-toggle {
    background-color: rgba($color: #000000, $alpha: 0.5);
  }
}
.password {
  margin-bottom: 2rem !important;
}
.email {
  margin-bottom: 2rem !important;
}
.dzu-previewContainer {
  .dzu-previewImage {
    width: auto;
    margin-left: 10px;
    max-height: 40px;
    max-width: 100%;
    border-radius: 4px;
  }
}

.dzu-dropzone {
  min-height: 100px !important;
}

.example::-webkit-scrollbar {
  display: none;
}

.add-hover {
  background-color: #f0f3f5;
  padding-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: green;
    color: white;
  }
}
.delete-hover {
  background-color: #f0f3f5;
  padding-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: red;
    color: white;
  }
}

.modal-dialog {
  max-width: initial;
  margin: 0 auto;
}
